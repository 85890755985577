import React from 'react';
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';

const FilterSelector = ({ filterValues, handleSubmit, selectedFilters, setSelectedFilters }) => {

  const handleSelectChange = (option, filter) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filter.label]: {
        selected: option
      }
    }));
  };

  const handleReset = () => {
    setSelectedFilters({});
  };

  const handleApply = (e) => {
    handleSubmit(e);
  };

  if (!filterValues || !Array.isArray(filterValues)) {
    return null;
  }

  return (
    <>
      {filterValues.map((filter, index) => (
        <Col md={6} key={filter.label}>
          {index === 1 && (
            <button
              className="btn filterReset"
              type="button"
              onClick={handleReset}
            >
              Reset
            </button>
          )}

          <Form.Label>{filter.label}</Form.Label>
          <Select
            id={filter.label}
            isMulti={filter.type === "modal"}
            onChange={(option) => handleSelectChange(option, filter)}
            value={selectedFilters[filter.label]?.selected || null}
            name={filter.label}
            placeholder={filter.label}
            options={filter.options.map(opt => ({
              label: opt.text,
              value: opt.value
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </Col>
      ))}
    </>
  );
};

export default FilterSelector;
